<template>
    <div>
        <notifications/>
        <div class="page-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
    </div>
</template>

<script>

export default {
    components : {},
    metaInfo   : {
        titleTemplate : '%s - Medico Pharm',
        htmlAttrs     : {
            lang : 'en',
            amp  : true
        }
    },
    name : 'MainLayout'
};

</script>
